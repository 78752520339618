.spinner-users-table {
    margin: 2% 0 2% 0;
    padding: 0 45% 0 45%;
}

.spinner-table {
    margin: 2% 0 2% 0;
    padding: 0 45% 0 45%;
}

.ModalHeaderTitle h5 {
    font-size: 1.1rem;
}

.itemCenter {
    text-align: center;
}

.viewTransaction {
    cursor: pointer;
}

.bg-gradient-success {
    background: linear-gradient(60deg, #20315c 0, #20315c 100%) !important; }

.pagination {
    margin: auto;
    padding: 0  35% 0 35%;
}

/* .cellTableCreate {
    color: blue;
    height: 10px !important;
} */

/* body {
    font-family: Helvetica, sans-serif;
  } */
  
  .react-autosuggest__container {
    position: static;
    top: 20px;
    left: 20px;
    /* background: blue; */
  }
  
  .react-autosuggest__input {
    width: 240px;
    height: 30px;
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
  }
  
  .react-autosuggest__input--focused {
    outline: none;
  }
  
  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .react-autosuggest__suggestions-container {
    display: none;
  }
  
  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 55%;
    width: 280px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 10000;
  }
  
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }
  
  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }
  
  .react-autosuggest__section-container {
    border-top: 1px dashed #ccc;
  }
  
  .react-autosuggest__section-container--first {
    border-top: 0;
  }
  
  .react-autosuggest__section-title {
    padding: 10px 0 0 10px;
    font-size: 12px;
    color: #777;
  }
  