.login-container {
    margin-top: -14em;
}

.login-navbar-logo img {
    margin: 2em 0 0 2em;
    height: 9rem !important;
}

.login-box .card {
    border-width: 0.1px !important;
    border-style: solid !important;
    border-color: #20315c !important;
    border-radius: 25px !important;
}

.form-control {
    color: #20315c !important;
}

/* azul #20315c azul claro #47a5c0 amarillo #efcd4d */

.bg-gradient-success1 {
    background: linear-gradient(60deg, #ffffff 0, #ffffff 100%) !important; }

.text-white {
    color: #20315c !important; }

.text-login-container {
    color: #47a5c0 !important;
}

@media (max-width: 990px) {
    .login-container {
      margin-top: -12em;
    }
    .login-navbar-logo img {
        height: 7rem !important;
        margin-left: -2%;
    }
}

@media (max-width: 768px) {
    .header {
        padding-top: 10rem !important; 
    }
    .login-navbar-logo img {
        margin-left: 80%;
    }
}
